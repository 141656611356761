<template>
	<div class="BestGroupList">
		<w-navTab titleText="今日协会排行"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="content">
			<div class="header-title">
				<div class="header-title-left">
					共{{orgList.length}}名
				</div>
				<div class="header-title-right" @click="isBestShow = true">
					<van-icon style="margin-right: 2px;" name="question-o" color="#3377FF" size="16" />排名规则
				</div>
			</div>
			<div class="body">
				<div class="body-list">
					<div class="body-list-item" v-for="(item,index) in orgList">
						<div class="list-item-top">
							<div class="body-item-left">
								<img :src="item.image" alt="">
								<div class="left-img-sort">{{index + 1}}</div>
							</div>
							<div class="body-item-right">
								<div class="body-right-name">{{item.title}}{{item.title == '上饶市' ? '平安义警协会':'平安义警分会'}}</div>
								<div class="body-right-text">参与活动人次：<span>{{item.activity_register}}次</span></div>
							</div>
						</div>
						<div class="list-item-footer">
							<div class="footer-item">
								<div class="footer-item-icon">
									<img src="../../assets/img/icon610.png" alt="">
								</div>
								<div class="footer-item-text">活动数量：{{item.activity_num}}个</div>
							</div>
							<div class="footer-item">
								<div class="footer-item-icon">
									<img src="../../assets/img/icon611.png" alt="">
								</div>
								<div class="footer-item-text">人均爱心值：{{item.per}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="isBestShow" :round="true">
			<div class="popup-bestList">
				<div class="popup-content">
					<div class="popup-title">计算方式：</div>
					<div class="popup-text">
						协会当日义警与志愿者获得的活动打卡爱心值总数除以协会当日活跃总人数
					</div>
					<div class="popup-title">排序方式：</div>
					<div class="popup-text">
						根据人均爱心值由大到小排列
					</div>
				</div>
				<div class="popup-footer" @click="isBestShow = false">我知道了</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import LoadingPage from '@/components/LoadingPage';
	import Best from '@/api/best';
	
	export default {
		name: 'BestGroupList',
		data() {
			return {
				isBestShow: false,
				loadingPage: true,
				orgList: [],
				limit: 10
			}
		},
		created() {
			this.getBestOrg()
		},
		methods: {
			/**
			 * 今日最佳协会
			 */
			getBestOrg(){
				Best.bestOrg()
					.then(result => {
						this.orgList = result.data
						this.loadingPage = false
					}, error => {
						console.log(error)
					})
			},
		},
		components:{
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.BestGroupList {
		padding-bottom: 20px;
		box-sizing: border-box;
		.content{
			.header-title{
				padding: 20px 16px 16px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				
				.header-title-left{
					color: #000;
					font-size: 16px;
					line-height: 24px;
				}
				.header-title-right{
					display: flex;
					align-items: center;
					color: #999;
					font-size: 13px;
					line-height: 20px;
				}
			}
			.body{
				.body-list{
					padding: 0 16px;
					box-sizing: border-box;
					
					.body-list-item{
						border-radius: 8px;
						background: #FFF;
						padding: 12px 12px 12px 16px;
						box-sizing: border-box;
						margin-bottom: 12px;
						
						&.body-list-item:last-child{
							margin-bottom: 0;
						}
						
						.list-item-top{
							display: flex;
							justify-content: space-between;
							align-items: center;
							
							.body-item-left{
								margin-right: 12px;
								width: 140px;
								height: 80px;
								position: relative;
								
								img{
									width: 100%;
									height: 100%;
									border-radius: 4px;
									object-fit: cover;
								}
								.left-img-sort{
									position: absolute;
									top: 0;
									left: 0;
									width: 24px;
									height: 24px;
									border-radius: 4px 0px;
									background: #FF6969;
									color: #FFF;
									text-align: center;
									font-size: 14px;
									line-height: 24px;
								}
							}
							.body-item-right{
								flex: 1;
								.body-right-name{
									color: #222;
									font-size: 18px;
									line-height: 27px;
								}
								.body-right-text{
									margin: 4px 0 7px;
									color: #777;
									font-size: 14px;
									line-height: 21px;
									span{
										color: #FF6969;
									}
								}
								.body-right-footer{
									display: flex;
									justify-content: space-between;
									align-items: center;
									
									.right-footer-number{
										color: #FF6969;
										font-size: 14px;
										line-height: 21px;
									}
									.right-footer-text{
										color: #AAAAA9;
										font-size: 12px;
										line-height: 21px;
									}
								}
							}
						}
					
						.list-item-footer{
							display: flex;
							justify-content: center;
							align-items: center;
							margin-top: 12px;
							
							.footer-item{
								display: flex;
								align-items: center;
								margin-right: 26px;
								
								&.footer-item:last-child{
									margin-right: 0;
								}
								
								.footer-item-icon{
									margin-right: 4px;
									width: 20px;
									height: 20px;
									img{
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}
								.footer-item-text{
									color: #777;
									font-size: 14px;
									line-height: 21px;
								}
							}
						}
					}
				}
			}
		}
		.popup-bestList{
			width: 292px;
			background: #FFF;
			
			.popup-content{
				padding: 0 27px;
				box-sizing: border-box;
				
				.popup-title{
					padding-top: 27px;
					color: #666;
					font-size: 16px;
					font-weight: bold;
					line-height: 24px;
					box-sizing: border-box;
				}
				.popup-text{
					color: #666;
					font-size: 14px;
					line-height: 21px;
					margin-top: 5px;
				}
			}
		
			.popup-footer{
				border-top: 1px solid #eee;
				padding: 10px 0 12px;
				text-align: center;
				color: #37F;
				font-size: 16px;
				line-height: 22px;
				box-sizing: border-box;
				margin-top: 19px;
			}
		}
	}
</style>
